@import "./index.scss";

//typewriter
.editor_handlebar {
  height: 16px;
  background-color: #57606a;
  border-radius: 10px 10px 0 0;
}
.editor_handlebar_buttons {
  padding-top: 5px;
  padding-left: 11px;
  display: flex;
}
.editor_handlebar_buttons:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #ff605c;
  margin-right: 4px;
}
.editor_handlebar_buttons:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #ffbd44;
  margin-right: 4px;
}
.editor_content {
  padding: 4rem;
  padding-bottom: 6rem;
  background: rgba($primary-dark, 0.9);
  border-radius: 0 0 10px 10px;
  color: $primary-light;
  height: 265px;
}

.code {
  color: $tag-color;
  font-size: 1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
  background-color: transparent !important;
}

.code span:nth-child(1)::before {
  content: "<p ";
}

.code span:nth-child(2)::after {
  color: $tag-color;
  content: "> ";
}

.code span:last-child::after {
  color: $tag-color;
  content: "\a </p>";
  white-space: pre;
}
.typewriter code {
  color: $primary-light;
  border-right: 0.15em solid $cursor;
  animation: blink-caret 0.75s step-end infinite;
}

.box_fade {
  border-radius: 40%;
  background-color: $primary-dark;
  border: none;
  box-shadow: inset 0px 0px 80px 60px rgba($primary-dark, 0.9);
}
.hero_wrapper {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='%23b5b5b5'%3e%3cpath stroke-opacity='0.15' d='M0 .5H31.5V32'/%3e%3c/svg%3e") !important;
}

.octagon {
  height: 504px;
  background-image: url("data:image/svg+xml, %3Csvg width='504' height='504' viewBox='0 0 604 604' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M407.679 0H196.321C183.9 0 171.969 4.93581 163.176 13.7288L13.7288 163.176C4.93581 171.969 0 183.9 0 196.321V407.679C0 420.1 4.93581 432.031 13.7288 440.824L163.176 590.271C171.969 599.064 183.867 604 196.321 604H407.679C420.1 604 432.031 599.064 440.824 590.271L590.271 440.824C599.064 432.031 604 420.133 604 407.679V196.321C604 183.9 599.064 171.969 590.271 163.176L440.791 13.7288C432.031 4.93581 420.1 0 407.679 0Z'  fill='%2395A7B8' fill-opacity='0.1' /%3E%3C/svg%3E");
  background-repeat: no-repeat !important;
  background-position: center;
}

.blur_box {
  background-color: rgba($primary-light, 0.2);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
}
.gallary_content {
  background: linear-gradient(0deg, #092231 0%, rgba(226, 229, 236, 0) 90.2%);
  background-blend-mode: multiply;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  backdrop-filter: blur(2px);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $cursor;
  }
}
