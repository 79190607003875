@import "./index.scss";

@media only screen and (max-width: 640px) {
  .App-hero {
    height: max-content;
  }
}
@media screen and (min-width: 768px) {
  nav div {
    justify-content: start !important;
  }
}

@media only screen and (max-width: 1024px) {
  .editor_content {
    padding: 2rem;
  }
  .octagon {
    background-image: none;
  }
}
