@import "index.scss";
@import "./Console.scss";
@import "./Responsive.scss";

.color-primary {
  color: $primary-dark;
}

.main {
  min-height: 100vh;
}
.container {
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-hero {
  display: flex;
  background-color: $primary-dark;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $size-lg;
  color: $primary-light;
  overflow: hidden;
}

div[data-testid="flowbite-tooltip"] {
  border-radius: 8px;
  padding: 10px;
  transition: 0.2s;
  width: 280px;
  filter: box-shadow(0px 10px 50px rgba(9, 31, 44, 0.15));
}

div[data-testid="flowbite-tooltip"] li {
  color: #68788a;
  width: 100%;
  display: inline-block;
  &:hover {
    color: $primary-dark;
    background-color: transparent;
  }
}

#link div[data-testid="flowbite-tooltip"] {
  width: max-content;
}
#link div[data-testid="flowbite-tooltip-target"] button {
  background-color: #dbeafe;
  color: #1e40af;
}

div[data-testid="flowbite-navbar-collapse"] ul li a {
  color: #68788a;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  width: 100%;
}

#modalBtn button {
  background-color: #fee2e2;
  color: #dc2626;
}

.sub-menu-arrow {
  opacity: 0;
  transition: transform 200ms ease-in, opacity 200ms ease-in,
    -webkit-transform 200ms ease-in;
  margin-top: 3px;
}
div[data-testid="flowbite-navbar-collapse"] ul li {
  display: flex;
  justify-content: space-between;
  align-items: middle;
}
div[data-testid="flowbite-navbar-collapse"] ul li:hover > .sub-menu-arrow {
  opacity: 1;
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

#navLinks:hover {
  color: $primary-dark;
}

//overriding nav links active state color
li a.md\:text-blue-700 {
  color: $primary-dark !important;
}

li a.md\:hover\:text-blue-700:hover {
  color: $primary-dark !important;
}

.tabs button {
  background: $primary-light !important;
  color: #091f2c96 !important;
  border: 1px solid #091f2c;
  font: normal normal normal 14px/46px;
  height: 29px;
  border-radius: 15px;
  margin-bottom: 68px;
  transition: 0.4s all;
  &:not(button:last-child) {
    margin-right: 11px;
  }
  &:hover {
    background: #e7f7ed 0% 0% no-repeat padding-box !important;
    color: $theme !important;
    border: 1px solid #0bab47;
  }
}
.tabs button[aria-selected="true"] {
  background: #e7f7ed 0% 0% no-repeat padding-box !important;
  color: $theme !important;
  border: 1px solid #0bab47;
}

.tabs div[role="tablist"] {
  justify-content: center;
}

.tab-cards img {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px solid #e2e5ec;
  transition: 0.4s all;
}
.tab-cards img:hover {
  border: 1px solid $theme;
  box-shadow: 0 10px 15px -3px rgb(4, 67, 28, 0.3),
    0 4px 6px -4px rgba(4, 67, 28, 0.2);
  transform: translateY(-10px);
}
.tab-cards div {
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding-top: 4px;
}

.tab-cards div:nth-child(2) {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.tab-badge {
  display: flex;
  justify-content: flex-end;
}

.tab-badge span {
  width: max-content;
}

footer {
  background-color: white !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

footer li {
  margin-top: 0.5rem !important;
  color: $primary-dark !important;
  font-size: 0.9rem !important;
}

footer h2 {
  margin-bottom: 1rem !important;
  color: $primary-dark !important;
  font-weight: 700 !important;
}

.google-btn Button {
  color: #444;
  border-color: $primary-dark;
  background-color: rgb(255 255 255 / 0.1);
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  transition: 0.2s all;
  &:hover {
    border-color: $primary-dark;
    background-color: rgb(149 167 184 / 0.3) !important;
  }
}

//select
select#category {
  background-color: transparent;
  padding: 0.1rem 0.4rem;
  width: 150px;
  margin: 6px 0px;
  border-radius: 4px;
}

//spinner
span[aria-label="spinner"] svg {
  fill: $theme !important;
}
